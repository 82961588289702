import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import Layout from '../components/layout'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { newGSTFaqs } from '../components/FAQs/faqs-list'
import FAQ from '../components/FAQs/FAQ'
import useFaqExpansion from '../hooks/useFaqExpansion'
import { NEW_GST } from '../components/internal-links'
import './gst-page.scss'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'New GST',
    url: NEW_GST,
  },
]

const NewGST: React.FC = () => {
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      rummyOneLink={rummyOneLinksStaticPage}
      pokerOneLink={pokerOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Mega Rummy &amp; Mega Poker GST Guides"
        description="Mega Rummy & Mega Poker GST Guides"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(newGSTFaqs)}
      />
      <div className="tds-policy-wrap">
        <Container>
          <h1>Mega Rummy & Mega Poker GST Guides</h1>
        </Container>
        <section>
          <Container>
            <h3>GST New Rule:</h3>
            <p>
              The Govt. of India has amended GST laws to levy 28% GST on every
              deposit (Face Value) on all online money gaming platforms,
              effective 1st October 2023.
            </p>
            <h2>What this implies for a player:</h2>
            <p>
              As per the new GST, any player who deposits in the game must pay a
              28% GST on the face value or the deposit amount.
            </p>
            <ul>
              <li>
                GST will not be deducted from the entry fee or for the number of
                games played.
              </li>
              <li>
                GST will only be deducted when depositing in the
                game/tournament.
              </li>
              <li>
                Users can add cash to their wallets without any GST or
                deductions.
              </li>
            </ul>
            <h2>Let&apos;s Understand Using an Example:</h2>
            <p>
              A player who deposits ₹100 will receive ₹78.12 in their account
              after a 28% GST deduction of ₹21.88.
            </p>
            <p>
              <strong>Useful Update!</strong> Mega is here to handle the GST on
              your behalf, and we&apos;re offering a 100% cashback for the GST
              you&apos;ll pay.
            </p>
            <h3>New Deposit Calculation</h3>
            <p>
              <strong>
                If you deposit ₹100, you will get the full amount of ₹100 in
                your account.
              </strong>
            </p>
            <table className="gst-table">
              <tr>
                <th>Players Action</th>
                <th>Mega Game</th>
              </tr>
              <tr>
                <td>Deposit Amount</td>
                <td>100</td>
              </tr>
              <tr>
                <td>28% GST Deduction</td>
                <td>
                  <span style={{ color: 'red' }}>-21.88</span>
                </td>
              </tr>
              <tr>
                <td>Credited Amount</td>
                <td>78.12</td>
              </tr>
              <tr>
                <td>
                  <strong>100% GST Cashback</strong>
                </td>
                <td>
                  <span style={{ color: 'green' }}>+21.88</span>
                </td>
              </tr>
              <tr>
                <td>Players Will Get</td>
                <td>
                  <strong>100</strong>
                </td>
              </tr>
            </table>
          </Container>
        </section>
        <section className="section-contd white-section">
          <Container>
            <h2>FAQs</h2>
            {newGSTFaqs.map((f, i) => {
              return (
                <React.Fragment key={f.question}>
                  <FAQ
                    question={f.question}
                    answer={f.answer}
                    answerElement={f.answerElement}
                    expanded={expandedFaq === i}
                    setExpanded={setExpandedFaq}
                    questinNumber={i}
                  />
                  {i !== newGSTFaqs.length - 1 ? (
                    <Container>
                      <Row className="s-row">
                        <Col lg={9}>
                          <div className="separator" />
                        </Col>
                      </Row>
                    </Container>
                  ) : null}
                </React.Fragment>
              )
            })}
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default NewGST
